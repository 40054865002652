import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@/app-config.module';

@Injectable({
  providedIn: 'root'
})
export class IncidentsService {
  endpoint = 'incident'

  constructor(
		private http: HttpClient,
		@Inject(APP_CONFIG) private config: AppConfig
  ) { }

  public getIncidents() {
    let url = `${this.config.apiUrl}/${this.endpoint}/list?start=0&length=99`;

    return this.http.get(url, { observe: 'response' })
      .pipe(
        catchError(this.handleError)
      );
  }

  public getIncident(id: string) {
    let url = `${this.config.apiUrl}/${this.endpoint}/get/${id}`;

    return this.http.get(url, { observe: 'response' })
      .pipe(
        catchError(this.handleError)
      );
  }

  public createIncident(submission: any) {
    let url = `${this.config.apiUrl}/${this.endpoint}/create`,
      data = {};


    for (let key in submission) {
      let value = submission[key]

      if(key === 'Date'){
        value = `${value.year}-${value.month}-${value.day}`
      }

      data[key] = value;
    }

    return this.http.post(url, data).pipe(catchError(this.handleError));
  }

  public deleteIncident(incidentID: string) {
    let url = `${this.config.apiUrl}/${this.endpoint}/delete/${incidentID}`;

    return this.http.delete(url, { observe: 'response' })
      .pipe(catchError(this.handleError))
  }

  public updateIncident(id, data: any) {
    let url = `${this.config.apiUrl}/${this.endpoint}/update/${id}`;

    return this.http.put(url, data).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    }
    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
